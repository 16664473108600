import React, {useState, useEffect} from 'react';
import post from '../utils/post.js';
import AppHeader from '../components/AppHeader.js';
import Calendar from '../components/Calendar.js';
import HomeRes from '../components/HomeRes.js';

export default function Home({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [dataMessages, setDataMessages] = useState([]);
	const [dataHours, setDataHours] = useState([]);
	const [showCal, setShowCal] = useState(false);
	const [today, setToday] = useState(new Date().getDate() + '.' + (new Date().getMonth() + 1) + '.' + new Date().getFullYear());
	const [now, setNow] = useState(new Date().getDate() + '.' + (new Date().getMonth() + 1) + '.' + new Date().getFullYear());
	const [reservation, setReservation] = useState({});
	const [showRes, setShowRes] = useState(false);
	const [usrCnt, setUsrCnt] = useState(0);
	const [timerCntId, setTimerCntId] = useState(null);
	const [counterCnt, setCounterCnt] = useState(14);

	useEffect(() => {
		getData(today);
		autoRefresh();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getData = (DT) => {
		setLoading(true);

		let currentDate = new Date().getDate() + '.' + (new Date().getMonth() + 1) + '.' + new Date().getFullYear();

		if (now !== currentDate) {
			setNow(currentDate);
			setToday(currentDate);
			DT = currentDate;
		}

		post('getListReservation', {DT: DT})
			.then((res) => {
				setData(res.data.data);
				setDataMessages(res.data.data_messages);
				setDataHours(res.data.data_hours.data);
				setUsrCnt(res.data.data_hours.data_cnt);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const handleRefresh = () => {
		getData(today);
		setCounterCnt(14);
	};

	useEffect(() => {
		return () => {
			if (timerCntId) clearTimeout(timerCntId);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timerCntId]);

	useEffect(() => {
		if (parseInt(counterCnt) === 0) {
			getData(today);
			//console.log('autoRefresh');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [counterCnt]);

	function autoRefresh() {
		if (timerCntId) {
			clearTimeout(timerCntId);
		}

		const newTimerId = setTimeout(() => {
			setCounterCnt((prev) => {
				let newVal = prev - 1;
				if (newVal < 0) {
					newVal = 14;
				}
				return newVal;
			});

			autoRefresh();
			//console.log('count');
		}, 1000);

		setTimerCntId(newTimerId);
	}

	const handleReaded = (ID) => {
		post('saveMessagesUsrReaded', {ID: ID})
			.then((res) => {
				setDataMessages((prevRows) => {
					const updatedRows = dataMessages.map((item, idex) => {
						if (item.ID === ID) {
							item.READED = 1;
						}

						return item;
					});

					return updatedRows;
				});
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const showCalendar = () => {
		setShowCal(true);
	};

	const closeCal = () => {
		setShowCal(false);
	};

	const todayChange = (val) => {
		setToday(val);
		getData(val);
		setCounterCnt(14);
	};

	const setDTToNow = () => {
		todayChange(now);
		setCounterCnt(14);
	};

	const addUpdateReservation = (item) => {
		if (parseInt(item.CANADD) === 1) {
			if (parseInt(item.LOCKED) === 0) {
				let isCurrentUser = false;
				let currentUser = [];

				item.USERS?.forEach((item2, index2) => {
					if (parseInt(item2.USRID) === parseInt(usrInfo.usrId)) {
						isCurrentUser = true;
						currentUser = item2;
					}
				});

				if (isCurrentUser) {
					//--- Edit Reservation ---
					setReservation({id: currentUser.ID, dt: currentUser.RESERVATIONDT, tm: currentUser.RESERVATIONDT_TIMES, lng: currentUser.RESERVATIONLNG, addPerson: currentUser.ADD_PERSON, lock: 0, usr_max: data.USERS_MAX});
					setShowRes(true);
				} else {
					//--- Add Reservation ---
					setReservation({id: 0, dt: item.DT1, tm: item.DT1S, lng: data.RESERV_LNG_LAST, addPerson: 0, lock: 0, usr_max: data.USERS_MAX});
					setShowRes(true);
				}
			} else {
				//--- Locked ---
				if (parseInt(usrInfo.usrId) === 1 || parseInt(usrInfo.usrId) === 4 || (parseInt(usrInfo.usrId) === 18 && parseInt(item.LOCKED_TYPEID) === 1)) {
					setReservation({id: item.LOCKED_ID, dt: item.DT1, tm: item.LOCKED_DT1S, lng: item.LNG, addPerson: 0, lock: item.LOCKED_TYPEID, usr_max: data.USERS_MAX});
					setShowRes(true);
				}
			}
		}
	};

	const closeRes = () => {
		setShowRes(false);
	};

	const updateReservations = (ID, DATE, LNG, ADDPERSON, LOCK) => {
		setLoading(true);

		post('saveReservation', {DT: today, ID: ID, DATE: DATE, LNG: LNG, ADDPERSON: ADDPERSON, LOCK: LOCK})
			.then((res) => {
				setData(res.data.data);
				setDataMessages(res.data.data_messages);
				setDataHours(res.data.data_hours.data);
				setUsrCnt(res.data.data_hours.data_cnt);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const deleteReservations = (ID, LOCK) => {
		setLoading(true);

		post('deleteReservation', {DT: today, ID: ID, LOCK: LOCK})
			.then((res) => {
				setData(res.data.data);
				setDataMessages(res.data.data_messages);
				setDataHours(res.data.data_hours.data);
				setUsrCnt(res.data.data_hours.data_cnt);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const prevDay = () => {
		let parts = today.split('.');
		let d = new Date(parts[2], parts[1] - 1, parts[0]);
		d.setDate(d.getDate() - 1);
		let newDate = d.getDate() + '.' + (d.getMonth() + 1) + '.' + d.getFullYear();

		setToday(newDate);
		getData(newDate);
		setCounterCnt(14);
	};

	const nextDay = () => {
		let parts = today.split('.');
		let d = new Date(parts[2], parts[1] - 1, parts[0]);
		d.setDate(d.getDate() + 1);
		let newDate = d.getDate() + '.' + (d.getMonth() + 1) + '.' + d.getFullYear();

		setToday(newDate);
		getData(newDate);
		setCounterCnt(14);
	};

	let listMessages = [];

	dataMessages?.forEach((item, index) => {
		if (item.READED === 0) {
			listMessages.push(
				<div className="list" key={index}>
					<div className="list_in">
						<div className="fl_l txt_l" style={{width: '100%'}}>
							<span className="fl_l">
								<b>{item.CRDTS}</b>
							</span>
							<div className="zero h1" />
							<span dangerouslySetInnerHTML={{__html: item.NOTE}}></span>
							<div className="zero h0" />
						</div>
						<div className="zero h1" />

						{item.READED === 0 ? (
							<button
								className="button green small"
								onClick={() => {
									handleReaded(item.ID);
								}}
							>
								Rozumím
							</button>
						) : (
							''
						)}
						<div className="zero h1" />
					</div>
				</div>
			);
		}
	});

	let listHours = [];

	dataHours?.forEach((item, index) => {
		let listUsers = [];
		let orderUsr = 0;
		let sOrderUsr = '';

		item.USERS?.forEach((item2, index2) => {
			let prevOrder = orderUsr + parseInt(item2.ADD_PERSON);
			if (parseInt(orderUsr) === 0) {
				prevOrder = 1;
			}
			orderUsr = orderUsr + 1 + parseInt(item2.ADD_PERSON);
			if (parseInt(item2.ADD_PERSON) > 0) {
				sOrderUsr = prevOrder.toString() + '.-' + orderUsr.toString() + '. ';
			} else {
				sOrderUsr = orderUsr.toString() + '. ';
			}

			listUsers.push(
				<div className="" key={index2}>
					<div className={`fl_l ${parseInt(item2.USRID) === parseInt(usrInfo.usrId) ? 'fnt_yellow fnt_b' : ''}`}>
						{parseInt(item2.NOT_PAYED) === 1 ? <img className="icon_thunder2 fl_l mr_05" alt="" /> : ''}
						<span className="fl_l mr_05">{sOrderUsr}</span>
						<span className="fl_l mr_05">{item2.USRNAME}</span>
						{item2.ADD_PERSON > 0 ? <span className="fl_l fnt_yellow">+{item2.ADD_PERSON}</span> : ''}
						{parseInt(item2.ADD_PERSON_PAYED) === 0 ? <img className="icon_thunder2 fl_l ml_05" alt="" /> : ''}
					</div>
					<div className="zero h0" />
				</div>
			);
		});

		listHours.push(
			<div key={index}>
				<div className={`reservation ${index % 2 === 0 ? 'fl_l' : 'fl_r'} ${parseInt(item.CANADD) === 1 ? 'cr_p' : 'cr_d'}`} style={{width: '48%'}} onClick={() => addUpdateReservation(item)}>
					<div className={`reservation_top ${data.CURRENT_HOURMIN === item.DT1S ? 'fnt_orange fnt_b' : ''}`}>
						{data.CURRENT_HOURMIN === item.DT1S ? (
							<div className="reservation_top_perc">
								<div className="reservation_top_perc_in" style={{width: data.CURRENT_HOURMIN_PERC + '%'}}></div>
							</div>
						) : (
							''
						)}
						{item.DT1S} - {item.DT2S}
						{item.USERS_CNT > 0 ? (
							<span className="fl_r fnt_1 mt_02">
								{item.USERS_CNT} / {data.USERS_MAX}
							</span>
						) : (
							''
						)}
					</div>
					<div className="zero h0" />
					<div className={`reservation_in ${parseInt(item.ENTRY_PERSON_CNT_OK) === 1 && parseInt(item.LOCKED) === 0 ? 'green' : 'red'}`}>
						{parseInt(item.LOCKED) === 1 ? (
							<>
								<div className="zero h03" />
								<span className="fl_l">{item.LOCKED_TXT}</span>
								<div className="zero h05" />
							</>
						) : (
							<>
								{listUsers.length > 0 ? (
									<>
										<div className="zero h03" />
										<span className="fl_l">{listUsers}</span>
										<div className="zero h03" />
									</>
								) : (
									''
								)}
							</>
						)}
					</div>
					<div className="zero h0" />
				</div>
				{index % 2 === 0 ? '' : <div className="zero h02" />}
			</div>
		);
	});

	let parts1 = today.split('.');
	let parts2 = now.split('.');
	let todayD = new Date(parts1[2], parts1[1] - 1, parts1[0]);
	let nowD = new Date(parts2[2], parts2[1] - 1, parts2[0]);

	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />
				<Calendar show={showCal} close={closeCal} defaultValue={today} returnValue={todayChange} dataEvents={data.data_cal_events} />
				<HomeRes showRes={showRes} closeRes={closeRes} data={reservation} dataHours={dataHours} updateReservations={updateReservations} deleteReservations={deleteReservations} usrInfo={usrInfo} />

				<div className="top">
					{todayD.getTime() >= nowD.getTime() ? (
						<div className="icon_refresh_main mr_1" onClick={() => handleRefresh()}>
							<div className="icon_refresh_in">{counterCnt}</div>
							<img className="icon_refresh fl_l cr_p mt_01" alt="" />
						</div>
					) : (
						<div className="icon_refresh_main mr_1"></div>
					)}

					<div className="fl_l txt_c mr_1" style={{width: '3rem'}} onClick={() => prevDay()}>
						<img className="icon_left cr_p" alt="" />
					</div>

					<div className="fl_l txt_c mr_1" style={{width: '3rem'}} onClick={() => nextDay()}>
						<img className="icon_right cr_p" alt="" />
					</div>

					<div className="cr_p ml_1" onClick={() => showCalendar()}>
						<span className="input-text-date-cal-icon3 fl_l mr_05"></span>
						<span className="fl_l mt_03">{today}</span>
					</div>
					{now !== today ? (
						<span className="fl_l mt_02 ml_2 lnk2 cr_p" onClick={() => setDTToNow()}>
							Dnes
						</span>
					) : (
						''
					)}
					<span className="fl_r mt_02">
						{usrCnt} rezervac{usrCnt === 0 || usrCnt > 4 ? 'í' : 'e'}
					</span>
				</div>

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`} style={{paddingTop: '0rem'}}>
					<div className="sticky">
						{data.PAYED_DAY < 400 ? (
							<>
								<div className="list announce" key="PERNAMENTMEMBER">
									<div className="list_in fnt_14">
										{data.PERNAMENTMEMBER === 0 ? (
											<>
												<span>Jednorázové vstupy</span>
											</>
										) : (
											<>
												<span className="fl_l">Členství do: {data.PAYEDTO_DTS}</span>
												<span className="fl_r">Zbývá dnů: {data.PAYED_DAYS}</span>
											</>
										)}

										<div className="zero h0" />
									</div>
								</div>
							</>
						) : (
							''
						)}
						{typeof data.MESSAGE !== 'undefined' && data.MESSAGE !== '' ? (
							<>
								<div className="message txt_l fnt_14" key="MESSAGES">
									<span className="" dangerouslySetInnerHTML={{__html: data.MESSAGE}}></span>
									<div className="zero h0" />
								</div>
								<div className="zero h05" />
							</>
						) : (
							''
						)}
						{data.TOPAY_TOTAL > 0 ? (
							<>
								<div className="topay txt_l fnt_14" key="ESHOP">
									<span className="fl_l">Nezaplacený nákup:</span>
									<span className="fl_r">{data.TOPAY_TOTALS},-</span>
									<div className="zero h0" />
								</div>
								<div className="zero h05" />
							</>
						) : (
							''
						)}
						{data.TOPAY_TOTAL < 0 ? (
							<>
								<div className="overpay txt_l fnt_14" key="ESHOP">
									<span className="fl_l">Předplaceno:</span>
									<span className="fl_r">{-1 * data.TOPAY_TOTALS},-</span>
									<div className="zero h0" />
								</div>
								<div className="zero h05" />
							</>
						) : (
							''
						)}
						{data.NOT_PAYED_CNT > 0 ? (
							<>
								<div className="list red" key="ENTRY">
									<div className="list_in fnt_14">
										<span className="fl_l">Nezaplacené vstupy:</span>
										<span className="fl_r">{data.NOT_PAYED_PRICES},-</span>
										{data.NOT_PAYED_DETAIL !== '' ? (
											<>
												<div className="zero h02" />
												<span className="fl_l txt_l fnt_12" dangerouslySetInnerHTML={{__html: data.NOT_PAYED_DETAIL}}></span>
											</>
										) : (
											''
										)}
										<div className="zero h0" />
									</div>
								</div>
							</>
						) : (
							''
						)}
						{data.NOT_PAYED_ADD_PERSON_CNT > 0 ? (
							<>
								<div className="list red" key="ADDPERS">
									<div className="list_in fnt_14">
										<span className="fl_l">Nezaplacené doprovody:</span>
										<span className="fl_r">{data.NOT_PAYED_ADD_PERSON_PRICES},-</span>
										{data.NOT_PAYED_ADD_PERSON_DETAIL !== '' ? (
											<>
												<div className="zero h02" />
												<span className="fl_l txt_l fnt_12" dangerouslySetInnerHTML={{__html: data.NOT_PAYED_ADD_PERSON_DETAIL}}></span>
											</>
										) : (
											''
										)}
										<div className="zero h0" />
									</div>
								</div>
							</>
						) : (
							''
						)}
					</div>

					{listMessages}
					<div className="zero h05" />

					{listHours}
				</div>
			</div>
		</>
	);
}
