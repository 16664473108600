import React, {useState, useEffect} from 'react';
import post from '../utils/post.js';
import AppHeader from '../components/AppHeader.js';
import Calendar from '../components/Calendar.js';
import ExercisePlan from '../components/ExercisePlan.js';
import $ from 'jquery';
import ChartArea from '../components/ChartArea.js';
import Cookies from 'js-cookie';
import ExerciseWorkoutRec from '../components/ExerciseWorkoutRec.js';

export default function Exercise({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [showCal, setShowCal] = useState(false);
	const [today, setToday] = useState(new Date().getDate() + '.' + (new Date().getMonth() + 1) + '.' + new Date().getFullYear());
	const [now, setNow] = useState(new Date().getDate() + '.' + (new Date().getMonth() + 1) + '.' + new Date().getFullYear());
	const [showPlan, setShowPlan] = useState(false);
	const [addVal1, setAddVal1] = useState([]);
	const [addVal2, setAddVal2] = useState([]);
	const [started, setStarted] = useState(false);
	const [startedWorkoutId, setStartedWorkoutId] = useState(0);
	const [timeSec, setTimeSec] = useState(0);
	const [timerCntId, setTimerCntId] = useState(null);
	const [selectedPlan, setSelectedPlan] = useState(0);

	useEffect(() => {
		setNow(new Date().getDate() + '.' + (new Date().getMonth() + 1) + '.' + new Date().getFullYear());
		getData(today);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return () => {
			if (timerCntId) clearTimeout(timerCntId);
		};
	}, [timerCntId]);

	const getData = (DT) => {
		setLoading(true);

		post('getListExercise', {DT: DT})
			.then((res) => {
				setData(res.data);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	useEffect(() => {
		data.data?.forEach((item) => {
			if (parseInt(item.TYPEID) === 1) {
				setAddVal1((prev) => {
					const updated = [...prev];
					updated[item.WORKOUTID] = item.LAST_CNT;
					return updated;
				});

				setAddVal2((prev) => {
					const updated = [...prev];
					updated[item.WORKOUTID] = item.LAST_WEIGHT;
					return updated;
				});
			} else {
				setAddVal1((prev) => {
					const updated = [...prev];
					updated[item.WORKOUTID] = item.LAST_TIMESEC_M;
					return updated;
				});

				setAddVal2((prev) => {
					const updated = [...prev];
					updated[item.WORKOUTID] = item.LAST_TIMESEC_S;
					return updated;
				});
			}
		});

		setTimeout(() => {
			if (data.data?.length > 0) {
				selectWorkout(selectedPlan);
			}
		}, 200);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const showCalendar = () => {
		setShowCal(true);
	};

	const closeCal = () => {
		setShowCal(false);
	};

	const todayChange = (val) => {
		setToday(val);
		getData(val);
	};

	const setDTToNow = () => {
		todayChange(now);
	};

	const handlePlan = () => {
		setShowPlan(true);
	};

	const closePlan = () => {
		setShowPlan(false);
	};

	const updatePlan = (recs) => {
		setLoading(true);

		let formdata = [];
		recs.forEach((item) => {
			if (item.ISCHECKED === 1) formdata.push(item.ID);
		});

		post('saveExercisePlan', {DT: today, CHCK: formdata})
			.then((res) => {
				setData(res.data);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const selectWorkout = (index) => {
		if (index > data.data.length - 1) {
			index = data.data.length - 1;
		}
		if (index < 0) {
			index = 0;
		}

		setSelectedPlan(index);

		$('.LIST_TAB_EXERCISE').each(function () {
			$(this).css('width', '6rem');
			$(this).css('background-color', '#777777');
		});

		const widthInPixels = document.getElementById('LIST_TAB_EXERCISE_TXT_' + index).offsetWidth;
		const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
		const widthInRem = widthInPixels / fontSize + 2;

		$('#LIST_TAB_EXERCISE_' + index).css('width', widthInRem + 'rem');
		$('#LIST_TAB_EXERCISE_' + index).css('background-color', '#bbbbbb');

		$('.LIST_EXERCISE').each(function () {
			$(this).addClass('hidden');
		});

		$('#LIST_EXERCISE_' + index).removeClass('hidden');
	};

	const setAddVal1Val = (val, workouteId) => {
		setAddVal1((prev) => {
			const updated = [...prev];
			updated[workouteId] = val;
			return updated;
		});
	};

	const setAddVal2Val = (val, workouteId) => {
		setAddVal2((prev) => {
			const updated = [...prev];
			updated[workouteId] = val;
			return updated;
		});
	};

	const addWorkout = (workouteId, typeId) => {
		let val1 = addVal1[workouteId] || 0;
		let val2 = addVal2[workouteId] || 0;

		setLoading(true);

		post('addExerciseWorkout', {DT: today, WORKOUTID: workouteId, TYPEID: typeId, VAL1: val1, VAL2: val2})
			.then((res) => {
				setData(res.data);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const showHideTimer = (workouteId) => {
		if ($('#TIMER_' + workouteId).is(':visible') === false) {
			$('#TIMER_' + workouteId).slideDown(300);
			$('#TIMER_TIME_' + workouteId).html('00:00');
			$('#TIMER_START_' + workouteId).show();
			$('#TIMER_STOP_' + workouteId).hide();
		} else {
			$('#TIMER_' + workouteId).slideUp(300);
			if (timerCntId) {
				clearTimeout(timerCntId);
			}
		}
	};

	useEffect(() => {
		if (started) {
			startCounter();
		} else {
			if (timerCntId) {
				clearTimeout(timerCntId);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [started]);

	const startTimer = (workoutId) => {
		$('.TIMER_START').hide();
		$('.TIMER_STOP').show();

		setStarted(true);
		setTimeSec(0);
		setStartedWorkoutId(workoutId);
		$('#TIMER_START_' + workoutId).hide();
		$('#TIMER_STOP_' + workoutId).show();
	};

	const stopTimer = () => {
		$('.TIMER_START').show();
		$('.TIMER_STOP').hide();

		$('#TIMER_START_' + startedWorkoutId).show();
		$('#TIMER_STOP_' + startedWorkoutId).hide();
		setStarted(false);
		setStartedWorkoutId(0);

		let tM = convertSeconds(timeSec).split(':')[0];
		let tS = convertSeconds(timeSec).split(':')[1];

		if (tM === '00') {
			tM = '0';
		} else {
			tM = tM.replace(/^0+/, '');
		}
		if (tS === '00') {
			tS = '0';
		} else {
			tS = tS.replace(/^0+/, '');
		}

		setAddVal2Val(tS, startedWorkoutId);
		setAddVal1Val(tM, startedWorkoutId);

		if (timerCntId) {
			clearTimeout(timerCntId);
		}
	};

	function convertSeconds(s) {
		let min = Math.floor(s / 60);
		let sec = s % 60;
		min = min.toString().length === 1 ? '0' + min.toString() : min.toString();
		sec = sec.toString().length === 1 ? '0' + sec.toString() : sec.toString();
		return min + ':' + sec;
	}

	useEffect(() => {
		$('#TIMER_TIME_' + startedWorkoutId).html(convertSeconds(timeSec));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeSec]);

	function startCounter() {
		if (timerCntId) {
			clearTimeout(timerCntId);
		}

		const newTimerId = setTimeout(() => {
			setTimeSec((prevTimeSec) => prevTimeSec + 1);
			startCounter();
		}, 1000);

		setTimerCntId(newTimerId);
	}

	const plan = [];

	data.data?.forEach((item, index) => {
		plan.push(
			<div className="list_tab noselectable LIST_TAB_EXERCISE" key={index} id={'LIST_TAB_EXERCISE_' + index} onClick={() => selectWorkout(index)}>
				<div className="list_tab_in">
					<div className="list_tab_in_cl" style={{backgroundColor: item.CL}}></div>
					<span className="" id={'LIST_TAB_EXERCISE_TXT_' + index}>
						{item.NAME}
					</span>
					<div className="zero h0" />
				</div>
				<div className="list_tab_bottom" style={{backgroundColor: item.COLOR}}></div>
				<div className="zero h0" />
			</div>
		);
	});

	const workout = [];

	data.data?.forEach((item, index) => {
		let addWeight = parseInt(item.LAST_WEIGHT) - parseInt(usrInfo.WEIGHT);
		let sOrder = 0;

		workout.push(
			<div className="LIST_EXERCISE hidden" key={index} id={'LIST_EXERCISE_' + index}>
				{item.NOTE !== '' ? (
					<>
						<span className="fl_l fnt_14">{item.NOTE}</span>
						<div className="zero h1" />
					</>
				) : (
					''
				)}
				{today === now ? (
					<>
						{item.TYPEID === 1 && item.WITHMYWEIGHT === 1 ? (
							<>
								<span className="fl_l fnt_14 mt_07">
									{usrInfo.WEIGHT} kg + {addWeight} kg závaží
								</span>
							</>
						) : (
							''
						)}

						<div className="fl_r">
							{item.TYPEID === 1 ? (
								<>
									<div className="fl_r ml_1 cr_p" onClick={() => addWorkout(item.WORKOUTID, item.TYPEID)}>
										<button className="button green fl_l">+</button>
									</div>
									<span className="fl_r ml_05 mr_05 mt_05">kg</span>
									<div className="fl_r" style={{width: '6rem'}}>
										<input className="input-text" type="number" step={0.01} maxLength={5} value={addVal2[item.WORKOUTID] || ''} onChange={(e) => setAddVal2Val(e.target.value, item.WORKOUTID)} />
									</div>
									<span className="fl_r ml_05 mr_05 mt_05">x</span>
									<div className="fl_r" style={{width: '6rem'}}>
										<input className="input-text" type="number" maxLength={3} value={addVal1[item.WORKOUTID] || ''} onChange={(e) => setAddVal1Val(e.target.value, item.WORKOUTID)} />
									</div>
								</>
							) : (
								<>
									<div className="fl_r ml_1 cr_p" onClick={() => addWorkout(item.WORKOUTID, item.TYPEID)}>
										<button className="button green fl_l">+</button>
									</div>
									<div className="fl_r" style={{width: '6rem'}}>
										<input className="input-text" type="number" maxLength={2} value={addVal2[item.WORKOUTID] || ''} onChange={(e) => setAddVal2Val(e.target.value, item.WORKOUTID)} />
									</div>
									<span className="fl_r ml_05 mr_05 mt_05">:</span>
									<div className="fl_r" style={{width: '6rem'}}>
										<input className="input-text" type="number" maxLength={3} value={addVal1[item.WORKOUTID] || ''} onChange={(e) => setAddVal1Val(e.target.value, item.WORKOUTID)} />
									</div>

									<img className="icon_timer mr_05 fl_r cr_p" onClick={() => showHideTimer(item.WORKOUTID)} alt="" />
								</>
							)}
						</div>
						<div className="zero h0" />

						<div className="hidden" id={'TIMER_' + item.WORKOUTID}>
							<b>
								<div className="fnt_10" id={'TIMER_TIME_' + item.WORKOUTID}>
									00:00
								</div>
							</b>
							<div className="zero h0" />
							<input className="button big green TIMER_START" type="button" value="Start" id={'TIMER_START_' + item.WORKOUTID} onClick={() => startTimer(item.WORKOUTID)} />
							<input className="button big red hidden TIMER_STOP" type="button" value="Stop" id={'TIMER_STOP_' + item.WORKOUTID} onClick={() => stopTimer()} />
							<div className="zero h3" />
						</div>
					</>
				) : (
					''
				)}
				<div className="zero h1" />

				{item.recs?.map((rec, index2) => {
					sOrder++;
					return <ExerciseWorkoutRec key={index2} item={item} rec={rec} index2={index2} sOrder={sOrder} today={today} setData={setData} />;
				})}

				<div className="zero h0" />
				<div className="fl_l" style={{width: '49%'}}>
					<div className="list">
						<div className="list_in">
							<div className="">Minule ({item.PREVIOUS_CRDTS})</div>
							<div className="zero h05" />

							<div className="chart_roy_main">
								<div className="chart_roy_in" style={{width: item.PERC2 + '%', backgroundImage: 'linear-gradient(to top,' + item.CL + ',#bbbbbb 110%)'}}>
									<span className="chart_roy_label fnt07">{item.PERC1}%</span>
								</div>
							</div>
							<div className="zero h05" />

							<span dangerouslySetInnerHTML={{__html: item.PREVIOUS_LIST_CNTWEIGHT3}}></span>
							<div className="zero h05" />
						</div>
					</div>
				</div>
				<div className="fl_r" style={{width: '49%'}}>
					<div className="list">
						<div className="list_in">
							<div className="">Nejlepší výkon ({item.BEST_CRDTS})</div>
							<div className="zero h05" />

							<div className="chart_roy_main">
								<div className="chart_roy_in" style={{width: item.PERC4 + '%', backgroundImage: 'linear-gradient(to top,' + item.CL3 + ',#bbbbbb 110%)'}}>
									<span className="chart_roy_label fnt07">{item.PERC3}%</span>
								</div>
							</div>
							<div className="zero h05" />

							<span dangerouslySetInnerHTML={{__html: item.BEST_LIST_CNTWEIGHT}}></span>
							<div className="zero h05" />
						</div>
					</div>
				</div>
				<div className="zero h02" />
				<div className="list">
					<div className="list_in">
						<div>
							<ChartArea data={item.data_graph.data} labels={item.data_graph.labels} color={item.COLOR} linesColor={Cookies.get('progressgym_theme') ? '#555555' : '#dddddd'} />
						</div>
						<div className="zero h0" />
					</div>
				</div>
				<div className="zero h0" />
			</div>
		);
	});

	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />
				<Calendar show={showCal} close={closeCal} defaultValue={today} returnValue={todayChange} dataEvents={data.data_cal_events} />
				<ExercisePlan showPlan={showPlan} closePlan={closePlan} data={data.data_plan} updatePlan={updatePlan} />

				<div className="top">
					<div className="cr_p" onClick={() => showCalendar()}>
						<span className="input-text-date-cal-icon3 fl_l mr_05"></span>
						<span className="fl_l mt_02">{today}</span>
					</div>
					{now !== today ? (
						<span className="fl_l mt_01 ml_2 lnk2 cr_p" onClick={() => setDTToNow()}>
							Dnes
						</span>
					) : (
						''
					)}

					<img className="icon_exerciseplan fl_r cr_p" onClick={() => handlePlan()} alt="" />
				</div>

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>
					{usrInfo.CLUBMEMBER === 0 && typeof usrInfo.MESSAGE !== 'undefined' && usrInfo.MESSAGE !== '' ? (
						<>
							<div className="message txt_l fnt_14" key="MESSAGES">
								<span className="" dangerouslySetInnerHTML={{__html: usrInfo.MESSAGE}}></span>
								<div className="zero h0" />
							</div>
							<div className="zero h05" />
						</>
					) : (
						''
					)}

					<div className="tab_panel_scroll" id="TAB_PANEL_MAIN">
						{plan}
					</div>
					<div className="zero h1" />

					{workout}
					<div className="zero h0" />
				</div>
			</div>
		</>
	);
}
