import React, {useState, useRef} from 'react';
import Cookies from 'js-cookie';
import post from '../utils/post.js';
import validateEmail from '../utils/validateEmail.js';
import FormText from '../components/FormText.js';
import {Link} from 'react-router-dom';

export default function Login() {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [usernameError, setUsernameError] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [loading, setLoading] = useState(false);
	const [result, setResult] = useState('Zadejte přihlašovací údaje');

	const usernameRef = useRef();
	const passwordRef = useRef();

	const handleSubmit = (e) => {
		e.preventDefault();

		setUsernameError('');
		setPasswordError('');

		let localError = '';

		if (!username) {
			localError = 'Vyplňte email';
			setUsernameError(localError);
			usernameRef.current.focus();
		} else if (!validateEmail(username)) {
			localError = 'Neplatný email';
			setUsernameError(localError);
			usernameRef.current.focus();
		} else if (!password) {
			localError = 'Vyplňte heslo';
			setPasswordError(localError);
			passwordRef.current.focus();
		}

		if (!localError) {
			setLoading(true);

			post('login', {username, password})
				.then((res) => {
					if (res.usrId) {
						window.location.pathname = '/';
					} else {
						setResult(res.resultTxt);
					}

					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	const setCookieAgree = () => {
		setLoading(true);
		Cookies.set('progressgym_cookie_agreement', '1', {expires: 365});
		window.location.pathname = '/';
	};

	return (
		<div className="login">
			{loading ? <div className="loading" /> : ''}

			{!Cookies.get('progressgym_cookie_agreement') ? (
				<div className="cookies">
					<div className="cookies_in">
						Pro pužívání aplikace potřebujeme uložit do Vašeho prohlížeče pouze bezpečnostní token potřebný pro příhlášení. Žádné osobní údaje.
						<div className="zero h1" />
						<input className="button green" type="button" name="button_cookie" value="Souhlasím" onClick={setCookieAgree} readOnly />
					</div>
				</div>
			) : (
				''
			)}

			<div className="zero h15" />
			<img className="login_logo" alt="" />
			<div className="zero h15" />
			<div className="login_container">
				<div className="login_container_in">
					<span>Přihlášení</span>
					<div className="zero h15"></div>

					<form method="POST" onSubmit={handleSubmit}>
						<FormText type="text" label="Email" value={username} setValue={setUsername} ref={usernameRef} error={usernameError} />
						<FormText type="password" label="Heslo" value={password} setValue={setPassword} ref={passwordRef} error={passwordError} />
						<div className="zero h1" />
						<input type="submit" name="button_login" value="Přihlásit" className="button" disabled={loading} />
						<div className="zero h1" />
						<span className="login_result">{result}</span>
					</form>
					<div className="zero h2"></div>

					<Link to={'/register'} key="lnk_register" className="lnk1 fl_l">
						Registrace
					</Link>
					<Link to={'/forget'} key="lnk_forget" className="lnk1 fl_r">
						Zapomenuté heslo
					</Link>
					<div className="zero h0"></div>
				</div>
			</div>
		</div>
	);
}
