import React, {useState, useRef, useEffect} from 'react';
import post from '../utils/post.js';
import FormText from './FormText.js';
import FormTextArea from './FormTextArea.js';
import FormDate from './FormDate.js';
import validateEmail from '../utils/validateEmail.js';
import validateRestorePassword from '../utils/validateRestorePassword.js';

export default function UserEdit({showEdit, closeEdit, updateUserRow, data}) {
	const [loading, setLoading] = useState(false);
	const [username, setUsername] = useState('');
	const [usernameError, setUsernameError] = useState('');
	const [firstName, setFirstName] = useState('');
	const [firstNameError, setFirstNameError] = useState('');
	const [lastName, setLastName] = useState('');
	const [lastNameError, setLastNameError] = useState('');
	const [nickname, setNickname] = useState('');
	const [nicknameError, setNicknameError] = useState('');
	const [phone, setPhone] = useState('');
	const [phoneError, setPhoneError] = useState('');
	const [prefix, setPrefix] = useState('');
	const [prefixError, setPrefixError] = useState('');
	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [password2, setPassword2] = useState('');
	const [passwordError2, setPasswordError2] = useState('');
	const [doorKey, setDoorKey] = useState('');
	const [doorKeyError, setDoorKeyError] = useState('');
	const [accountNumber, setAccountNumber] = useState('');
	const [accountNumberError, setAccountNumberError] = useState('');
	const [topayTotal, setTopayTotal] = useState('');
	const [topayTotalError, setTopayTotalError] = useState('');
	const [payedtoDT, setPayedtoDT] = useState('');
	const [payedtoDTError, setPayedtoDTError] = useState('');
	const [message, setMessage] = useState('');
	const [messageError, setMessageError] = useState('');

	const usernameRef = useRef();
	const firstNameRef = useRef();
	const lastNameRef = useRef();
	const nicknameRef = useRef();
	const phoneRef = useRef();
	const prefixRef = useRef();
	const passwordRef = useRef();
	const passwordRef2 = useRef();
	const doorKeyRef = useRef();
	const accountNumberRef = useRef();
	const topayTotalRef = useRef();
	const payedtoDTRef = useRef();
	const messageRef = useRef();

	useEffect(() => {
		setPassword('');
		setPassword2('');
	}, [showEdit]);

	useEffect(() => {
		setUsername(data.EMAIL || '');
		setFirstName(data.FIRSTNAME || '');
		setLastName(data.LASTNAME || '');
		setNickname(data.NICKNAME || '');
		setPhone(data.PHONE_NUMBER || '');
		setPrefix(data.PHONE_PREFIX || '');
		setPassword('');
		setPassword2('');
		setDoorKey(data.DOORKEY || '');
		setAccountNumber(data.ACCOUNTNUMBER || '');
		setTopayTotal(data.TOPAY_TOTAL || 0);
		setPayedtoDT(data.PAYEDTO_DT || '');
		setMessage(data.MESSAGE || '');

		setUsernameError('');
		setFirstNameError('');
		setLastNameError('');
		setNicknameError('');
		setPhoneError('');
		setPrefixError('');
		setPasswordError('');
		setPasswordError2('');
		setDoorKeyError('');
		setAccountNumberError('');
		setTopayTotalError('');
		setPayedtoDTError('');
		setMessageError('');
	}, [data]);

	const handleSubmit = (e) => {
		e.preventDefault();

		setUsernameError('');
		setFirstNameError('');
		setLastNameError('');
		setNicknameError('');
		setPhoneError('');
		setPrefixError('');
		setPasswordError('');
		setPasswordError2('');
		setDoorKeyError('');
		setAccountNumberError('');
		setTopayTotalError('');
		setPayedtoDTError('');
		setMessageError('');

		let localError = 0;

		if (!username) {
			localError = 1;
			setUsernameError('povinné');
			usernameRef.current.focus();
		} else if (!validateEmail(username)) {
			localError = 1;
			setUsernameError('Neplatný email');
			usernameRef.current.focus();
		}

		if (!firstName) {
			if (localError === 0) {
				firstNameRef.current.focus();
			}
			localError = 1;
			setFirstNameError('povinné');
		}

		if (!lastName) {
			if (localError === 0) {
				lastNameRef.current.focus();
			}
			localError = 1;
			setLastNameError('povinné');
		}

		if (!prefix) {
			if (localError === 0) {
				prefixRef.current.focus();
			}
			localError = 1;
			setPrefixError('*');
		}

		if (!phone || phone.length < 9) {
			if (localError === 0) {
				phoneRef.current.focus();
			}
			localError = 1;
			setPhoneError('*');
		}

		if (password !== '') {
			const sError = validateRestorePassword(password, password2);
			setPasswordError(sError.password);
			setPasswordError2(sError.password2);

			if (sError.password !== '') {
				if (localError === 0) {
					passwordRef.current.focus();
				}
				localError = 1;
			}

			if (sError.password2 !== '') {
				if (localError === 0) {
					passwordRef2.current.focus();
				}
				localError = 1;
			}
		}

		if (localError === 0) {
			setLoading(true);

			post('saveUser', {username, firstName, lastName, nickname, phone, prefix, password, password2, doorKey, accountNumber, topayTotal, payedtoDT, message, id: data.ID})
				.then((res) => {
					if (parseInt(res.data) === 1) {
						closeEdit();
						updateUserRow(username, firstName, lastName, nickname, phone, prefix, doorKey, data.ID, message, payedtoDT);
					}

					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	const saveAddPersonPayed = (e, index2, id, val) => {
		e.preventDefault();
		setLoading(true);

		post('saveAddPersonPayed', {ID: id, ADD_PERSON_PAYED: val})
			.then((res) => {
				data.not_payed_add_person[index2].ADD_PERSON_PAYED = res.data;
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	let listNotPayedAddPerson = [];

	if (showEdit) {
		data.not_payed_add_person.forEach((line2, index2) => {
			listNotPayedAddPerson.push(
				<div className="list announce" key={`NOT_PAYED_ADD_PERSON` + index2}>
					<div className="list_in">
						<span className="fl_l">{line2.RESERVATIONDTS}</span>
						<img className={`icon1 ${parseInt(line2.ADD_PERSON_PAYED) === 0 ? 'ok0' : 'ok1'} fl_r cr_p ml_2`} onClick={(e) => saveAddPersonPayed(e, index2, line2.ID, line2.ADD_PERSON_PAYED)} alt="" />
						<span className="fl_r">{line2.NOT_PAYED_PRICES},-</span>
						<div className="zero h0" />
					</div>
				</div>
			);
		});
	}

	return (
		<>
			{showEdit ? (
				<div className="popup">
					<div className="popup_content">
						<form method="POST" onSubmit={handleSubmit}>
							<div className="popup_content_in_head">
								<div className="popup_content_in_head_in">Editace uživatele</div>
								<img
									alt=""
									className="popup_content_in_head_in-close"
									onClick={() => {
										closeEdit();
									}}
								/>
							</div>
							<div className="popup_content_in_body">
								<div className="popup_content_in_body_in">
									<FormText type="text" label="Email" value={username} setValue={setUsername} ref={usernameRef} error={usernameError} />

									<div className="fl_l" style={{width: '48%'}}>
										<FormText type="text" label="Jméno" value={firstName} setValue={setFirstName} ref={firstNameRef} error={firstNameError} />
									</div>
									<div className="fl_r" style={{width: '48%'}}>
										<FormText type="text" label="Příjmení" value={lastName} setValue={setLastName} ref={lastNameRef} error={lastNameError} />
									</div>

									<div className="fl_l" style={{width: '48%'}}>
										<FormText type="text" label="Přezdívka" value={nickname} setValue={setNickname} ref={nicknameRef} error={nicknameError} />
									</div>
									<div className="fl_r" style={{width: '48%'}}>
										<div className="fl_r" style={{width: '60%'}}>
											<FormText type="text" label="Telefon" placeholder="111222333" maxLength="9" value={phone} setValue={setPhone} ref={phoneRef} error={phoneError} />
										</div>
										<div className="fl_l" style={{width: '36%'}}>
											<FormText type="text" label="Předv." value={prefix} placeholder="420" maxLength="3" setValue={setPrefix} ref={prefixRef} error={prefixError} />
										</div>
									</div>

									<div className="fl_l" style={{width: '48%'}}>
										<FormText type="password" label="Heslo" value={password} setValue={setPassword} ref={passwordRef} error={passwordError} />
									</div>
									<div className="fl_r" style={{width: '48%'}}>
										<FormText type="password" label="Znovu hesla" value={password2} setValue={setPassword2} ref={passwordRef2} error={passwordError2} />
									</div>

									<div className="fl_l" style={{width: '48%'}}>
										<FormText type="text" label="Klíč" value={doorKey} setValue={setDoorKey} ref={doorKeyRef} error={doorKeyError} />
									</div>
									<div className="fl_r" style={{width: '48%'}}>
										<FormText type="text" label="Číslo účtu (oddělovač ,)" value={accountNumber} setValue={setAccountNumber} ref={accountNumberRef} error={accountNumberError} />
									</div>

									<div className="fl_l" style={{width: '48%'}}>
										<FormText type="text" label="Dluh" value={topayTotal} setValue={setTopayTotal} ref={topayTotalRef} error={topayTotalError} />
									</div>
									<div className="fl_r" style={{width: '48%'}}>
										<FormDate label="Zaplaceno do" maxLength="10" readOnly value={payedtoDT} setValue={setPayedtoDT} ref={payedtoDTRef} error={payedtoDTError} />
									</div>

									<FormTextArea type="text" label="Zpráva" height="7rem" value={message} setValue={setMessage} ref={messageRef} error={messageError} />

									{listNotPayedAddPerson.length > 0 ? (
										<>
											<div className="zero h0" />
											<span className="fl_l fnt_orange">Nezaplacené doprovody:</span>
											<div className="zero h05" />
											{listNotPayedAddPerson}
										</>
									) : (
										''
									)}

									<div className="zero h0" />
								</div>
							</div>
							<div className="popup_content_in_foot">
								<div className="popup_content_in_foot_in">
									<input type="submit" name="button_save" value="Uložit" className="button green mr_2" disabled={loading} />
									<button
										className="button"
										onClick={() => {
											closeEdit();
										}}
									>
										Zpět
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}
