import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import post from '../utils/post.js';
import AppHeader from '../components/AppHeader.js';
import Search from '../components/Search.js';
import UserQR from './UserQR.js';
import Calendar from '../components/Calendar.js';
import $ from 'jquery';

export default function Member({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [showQR, setShowQR] = useState(false);
	const [qrInfo, setQrInfo] = useState({});
	const [showCal, setShowCal] = useState(false);
	const [toPayDT, setToPayDT] = useState('');
	const [toPayDTID, setToPayDTID] = useState('');
	const [toPayDTIndex, setToPayDTIndex] = useState('');
	const [toPayDTType, setToPayDTType] = useState('');
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		let search = '';

		if (typeof window.location.pathname.split('/')[2] != 'undefined') {
			search = decodeURI(window.location.pathname.split('/')[2]);
			setSearchValue(search);
		}

		getData(search);

		if (search !== '') {
			window.history.pushState({}, document.title, '/member');
		}
	}, []);

	const onChangeSearch = (value) => {
		setSearchValue(value);
		getData(value);
	};

	const getData = (search) => {
		setLoading(true);

		post('getListMember', {search: search})
			.then((res) => {
				setData(res.data);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	useEffect(() => {
		if (showQR) {
			$('#QRCODE').html(qrInfo?.QR);
			$('#QRTXT').html(qrInfo?.VCARD2);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showQR]);

	const getQRData = (id) => {
		setLoading(true);

		post('getQRData', {ID: id})
			.then((res) => {
				setQrInfo(res.data);
				setShowQR(true);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const closeQR = () => {
		setShowQR(false);
	};

	const showHideMore = (ID) => {
		if ($('#SHOW_MORE_' + ID).is(':visible') === false) {
			$('#SHOW_MORE_' + ID).show(250);
		} else {
			$('#SHOW_MORE_' + ID).hide(250);
		}
	};

	const savePermanent = (index, type, id, val) => {
		setLoading(true);

		let dat = [];
		if (type === 'perm0') {
			dat = data.perm0;
		} else if (type === 'perm1') {
			dat = data.perm1;
		}

		post('savePermanent', {ID: id, PERNAMENTMEMBER: val})
			.then((res) => {
				dat[index].PERNAMENTMEMBER = res.data;
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const showCalendar = (index, type, id, dt) => {
		setToPayDTID(id);
		setToPayDT(dt);
		setToPayDTIndex(index);
		setToPayDTType(type);
		setShowCal(true);
	};

	const closeCal = () => {
		setShowCal(false);
	};

	const toPayDTReturnValue = (val) => {
		setLoading(true);

		let dat = [];
		if (toPayDTType === 'perm0') {
			dat = data.perm0;
		} else if (toPayDTType === 'perm1') {
			dat = data.perm1;
		}

		post('saveToPayDT', {ID: toPayDTID, PAYEDTO_DT: val})
			.then((res) => {
				setLoading(false);
				dat[toPayDTIndex].PAYEDTO_DTS = val;
				dat[toPayDTIndex].PAYEDTO_DTS2 = val.slice(0, -4) + val.slice(-2);
				dat[toPayDTIndex].NOT_PAYED = res.data['NOT_PAYED'];
				dat[toPayDTIndex].PAYED_ORANGE = res.data['PAYED_ORANGE'];
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	let list = [];
	let list2 = [];
	let cntNotPayed = 0;
	let cntNotPayedAddPerson = 0;
	let cntMessage = 0;
	let cntToPay = 0;
	let cntOverPay = 0;

	const getList = (type) => {
		let i = 0;
		let lst = [];
		let dat = [];

		if (type === 'perm0') {
			dat = data.perm0;
		} else if (type === 'perm1') {
			dat = data.perm1;
		}

		dat?.forEach((line, index) => {
			i++;

			if (line.NOT_PAYED > 0) {
				cntNotPayed++;
			}

			if (line.not_payed_add_person.length > 0) {
				cntNotPayedAddPerson++;
			}

			if (line.MESSAGE !== '') {
				cntMessage++;
			}

			if (line.TOPAY_TOTAL > 0) {
				cntToPay++;
			}

			if (line.TOPAY_TOTAL < 0) {
				cntOverPay++;
			}

			let usrName = '';
			if (line.NICKNAME !== '') {
				usrName = `${line.LASTNAME} ${line.FIRSTNAME} (${line.NICKNAME})`;
			} else {
				usrName = `${line.LASTNAME} ${line.FIRSTNAME}`;
			}

			let cl = '';

			if (parseInt(line.PERNAMENTMEMBER) === 1) {
				if (parseInt(line.PAYED_ORANGE) === 1) {
					cl = 'fnt_yellow';
				}
				if (parseInt(line.PAYED_RED) === 1) {
					cl = 'fnt_orange';
				}
				if (parseInt(line.PAYED_RED) === 1 && line.USR_RECORDS5_LASTDT2 > 14 && line.PAYED_RED_DAYS > 14) {
					cl = 'fnt_gray';
				}
			}

			let listReservation = [];

			line.RESERVATIONS?.forEach((line2, index2) => {
				listReservation.push(
					<div key={`reslst_` + index2}>
						<div className="fl_l">
							<div className="fl_l txt_l">
								{line2.RESERVATIONDT} - {line2.RESERVATIONLNG_H}h{line2.ADD_PERSON > 0 ? ` +${line2.ADD_PERSON}x` : ''}
							</div>
						</div>
						<div className="zero h02" />
					</div>
				);
			});

			let listNotPayedAddPerson = [];

			line.not_payed_add_person.forEach((line2, index2) => {
				listNotPayedAddPerson.push(
					<div key={`NOT_PAYED_ADD_PERS` + index2}>
						<span className="fl_l">{line2.RESERVATIONDTS}</span>
						<span className="fl_r">{line2.NOT_PAYED_PRICES},-</span>
						<div className="zero h0" />
					</div>
				);
			});

			lst.push(
				<div className={`list ${cl}`} key={index}>
					<div className="list_in">
						<div className="cr_p" onClick={() => showHideMore(line.ID)}>
							<div className="fl_l">
								<span className="fnt_12">
									{i}. {usrName}
								</span>{' '}
								- <span className={`fnt_11 ${parseInt(line.LASTACTIVITY_IN) === 0 ? 'fnt_green' : ''} `}>{line.LASTACTIVITYDTS}</span>
							</div>
							<div className="fl_r fnt_11">{line.PAYEDTO_DTS2}</div>

							<img className={`icon1 key ${line.DOORKEY !== '' ? 'active' : ''} fl_r mr_05`} alt="" />
							{line.NOT_PAYED > 0 ? <img className="icon_thunder small fl_r mr_05 mt_01" alt="" /> : ''}
							{line.TOPAY_TOTAL > 0 ? <div className="icon_topay small fl_r mr_05 mt_01" /> : ''}
							{line.TOPAY_TOTAL < 0 ? <div className="icon_overpay small fl_r mr_05 mt_01" /> : ''}
							{line.MESSAGE !== '' ? <div className="icon_message small fl_r mr_05 mt_01" /> : ''}
							{line.not_payed_add_person.length > 0 ? <div className="icon_notpayedaddperson small fl_r mr_05 mt_01" /> : ''}

							<div className="zero h0" />
						</div>

						<div className="hidden" id={`SHOW_MORE_` + line.ID}>
							{line.NOT_PAYED > 0 ? (
								<>
									<div className="zero h1" />
									<div className="list red" key="ENTRY">
										<div className="list_in fnt_14">
											<span className="fl_l">Nezaplacené vstupy:</span>
											<span className="fl_r">{line.NOT_PAYED_PRICES},-</span>
											{line.NOT_PAYED_DETAIL !== '' ? (
												<>
													<div className="zero h02" />
													<span className="fl_l txt_l fnt_12" dangerouslySetInnerHTML={{__html: line.NOT_PAYED_DETAIL}}></span>
												</>
											) : (
												''
											)}
											<div className="zero h0" />
										</div>
									</div>
								</>
							) : (
								''
							)}

							{listNotPayedAddPerson.length > 0 ? (
								<>
									<div className="zero h1" />
									<div className="list announce" key="ADD_PERSON_NOT_PAYED">
										<div className="list_in">
											<span className="fl_l">Nezaplacené doprovody:</span>
											<div className="zero h05" />
											{listNotPayedAddPerson}
										</div>
									</div>
								</>
							) : (
								''
							)}

							{line.TOPAY_TOTAL > 0 ? (
								<>
									<div className="zero h1" />
									<div className="topay">
										<div className="fl_l txt_l">Nezaplacený nákup:</div>
										<div className="fl_r txt_r">{line.TOPAY_TOTALS},-</div>
										<div className="zero h0" />
									</div>
								</>
							) : (
								''
							)}

							{line.TOPAY_TOTAL < 0 ? (
								<>
									<div className="zero h1" />
									<div className="overpay">
										<div className="fl_l txt_l">Předplaceno:</div>
										<div className="fl_r txt_r">{-1 * line.TOPAY_TOTALS},-</div>
										<div className="zero h0" />
									</div>
								</>
							) : (
								''
							)}

							{line.MESSAGE !== '' ? (
								<>
									<div className="zero h1" />
									<div className="message txt_l">
										<span className="" dangerouslySetInnerHTML={{__html: line.MESSAGE}}></span>
										<div className="zero h0" />
									</div>
								</>
							) : (
								''
							)}

							<div className="zero h0" />

							<div className="fl_l">
								<div className="zero h1" />
								<a className={`lnk1 ${cl} fl_l cr_p`} href={`mailto:${line.EMAIL}?subject=Progress Gym&body=`}>
									{line.EMAIL}
								</a>
								<div className="zero h15" />

								<div className="fl_l">Návěštěvnost: {line.USR_RECORDS}x</div>
								<div className="zero h1" />

								<div className="fl_l">{listReservation}</div>
							</div>

							<div className="fl_r">
								<div className="zero h1" />
								<a className={`lnk1 ${cl} fl_r cr_p`} href={`tel:+${line.PHONES}`}>
									{line.PHONES}
								</a>
								<div className="zero h2" />

								<img
									alt=""
									className="icon2 qr fl_r cr_p"
									onClick={() => {
										getQRData(line.ID);
									}}
								/>
								<span className="input-text-date-cal-icon3 fl_r mr_2" onClick={() => showCalendar(index, type, line.ID, line.PAYEDTO_DTS)}></span>
								{usrInfo.usrId === 1 ? <img className={`icon2 permanent ${parseInt(line.PERNAMENTMEMBER) === 1 ? 'active' : ''} fl_r cr_p mr_2`} onClick={() => savePermanent(index, type, line.ID, line.PERNAMENTMEMBER)} alt="" /> : ''}
								<div className="zero h2" />

								{usrInfo.usrId === 1 ? (
									<>
										<Link to={`/userlog/${line.EMAIL}`} key="lnk_usrlog">
											<img className="icon2 userlog fl_r cr_p ml_2" alt="" />
										</Link>
										<Link to={`/user/${line.LASTNAME} ${line.FIRSTNAME}`} key="lnk_usr">
											<img className="icon2 user fl_r cr_p ml_2" alt="" />
										</Link>
									</>
								) : (
									''
								)}
								<Link to={`/eshopsysadmin/${line.LASTNAME} ${line.FIRSTNAME}`} key="lnk_eshop">
									<img className="icon2 eshop active fl_r cr_p ml_2" alt="" />
								</Link>
								<Link to={`/payment/${line.LASTNAME} ${line.FIRSTNAME}`} key="lnk_payment">
									<img className="icon2 permanent2 fl_r cr_p ml_2" alt="" />
								</Link>
							</div>

							<div className="zero h0" />
						</div>
						<div className="zero h0" />
					</div>
				</div>
			);
		});

		return lst;
	};

	list = getList('perm0');
	list2 = getList('perm1');

	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />
				<Search showSearch searchValue={searchValue} onChangeSearch={(e) => onChangeSearch(e)}>
					<img className="icon1 permanent fl_l mt_03" alt="" />
					<span className="fl_l mr_1 mt_03 fnt_13">{list.length}</span>

					<img className="icon1 permanent active fl_l mt_03" alt="" />
					<span className="fl_l mr_13 mt_03 fnt_13">{list2.length}</span>

					<img className="icon_thunder small fl_l mt_05 mr_01" alt="" />
					<span className="fl_l mr_13 mt_03 fnt_13">{cntNotPayed}</span>

					<div className="icon_notpayedaddperson small fl_l mt_05 mr_01" />
					<span className="fl_l mr_13 mt_03 fnt_13">{cntNotPayedAddPerson}</span>

					<div className="icon_topay small fl_l mt_05 mr_01" />
					<span className="fl_l mr_13 mt_03 fnt_13">{cntToPay}</span>

					<div className="icon_overpay small fl_l mt_05 mr_01" />
					<span className="fl_l mr_13 mt_03 fnt_13">{cntOverPay}</span>

					<div className="icon_message small fl_l mt_05 mr_01" />
					<span className="fl_l mt_03 fnt_13">{cntMessage}</span>
				</Search>
				<UserQR showQR={showQR} closeQR={closeQR} qrInfo={qrInfo} />
				<Calendar show={showCal} close={closeCal} defaultValue={toPayDT} returnValue={toPayDTReturnValue} />

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>
					<div className="fl_l">Bez permanentky</div>
					<div className="zero h1" />
					{list}

					<div className="zero h1" />
					<div className="fl_l">S permanentkou</div>
					<div className="zero h1" />
					{list2}

					<div className="zero h0" />
				</div>
			</div>
		</>
	);
}
