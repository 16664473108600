import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import post from '../utils/post.js';
import AppHeader from '../components/AppHeader.js';
import Search from '../components/Search.js';
import clipboard from '../utils/clipboard.js';
import PaymentNew from '../components/PaymentNew.js';
import UserEdit from '../components/UserEdit.js';
import FormDate from '../components/FormDate.js';

export default function Trans({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [info, setInfo] = useState('');
	const [data, setData] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [offset, setOffset] = useState(0);
	const [loadedCnt, setLoadedCnt] = useState(0);
	const [showNew, setShowNew] = useState(false);
	const [dataUser, setDataUser] = useState({});
	const [dataNew, setDataNew] = useState([]);
	const [showEditUser, setShowEditUser] = useState(false);
	const [dataEditUser, setDataEditUser] = useState([]);
	const [dataLog, setDataLog] = useState([]);
	const [downloadFrom, setDownloadFrom] = useState('');
	const [downloadTo, setDownloadTo] = useState('');
	const [currPaymentId, setCurrPaymentId] = useState(0);

	const downloadFromRef = useRef();
	const downloadToRef = useRef();

	useEffect(() => {
		let date = new Date();
		let day = date.getDate() - 1;
		let day2 = date.getDate();
		let month = date.getMonth() + 1;
		let year = date.getFullYear();
		setDownloadFrom(day + '.' + month + '.' + year);
		setDownloadTo(day2 + '.' + month + '.' + year);

		getData('', 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (offset > 0) {
			getData(searchValue, offset);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offset]);

	const loadNext = () => {
		setOffset((prevOffset) => {
			let newOffset = prevOffset + usrInfo.LIST_ROWS;
			return newOffset;
		});
	};

	const onChangeSearch = (value) => {
		setSearchValue(value);
		setOffset(0);
		getData(value, 0);
	};

	const getData = (search, offset) => {
		setLoading(true);

		post('getListTrans', {search: search, offset: offset})
			.then((res) => {
				if (offset === 0) {
					setData(res.data.data);
				} else {
					setData((prevRows) => {
						const updatedRows = [...data, ...res.data.data];
						return updatedRows;
					});
				}

				setDataLog(res.data.data_log);
				setDataUser(res.data.data_user);
				setLoadedCnt(res.data.data.length);

				setInfo(res.data.data_totals);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const isOK = (index, id) => {
		if (usrInfo.usrId === 1) {
			setLoading(true);

			post('saveTransOK', {ID: id})
				.then((res) => {
					data[index].ISOK = 1;
					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	const addPayment = (item) => {
		setCurrPaymentId(item.ID);

		let newNote = item.COLUMN10;

		if (item.USRID > 0) {
			newNote = '';
		}

		setDataNew({id: item.ID, paymentDT: item.COLUMN0, amount: item.COLUMN1X, usrId: item.USRID, note: newNote});

		setShowNew(true);
	};

	const closeNew = () => {
		setShowNew(false);
	};

	const updateRow = (row) => {
		let index = data.findIndex((item) => parseInt(item.ID) === parseInt(currPaymentId));

		if (index > -1) {
			data[index].HIDDENADD = 1;
			setData((prevRows) => {
				const updatedRows = [...data];
				return updatedRows;
			});
		}
	};

	const editUser = (item) => {
		setDataEditUser({ID: item.USRID, EMAIL: item.EMAIL, FIRSTNAME: item.FIRSTNAME, LASTNAME: item.LASTNAME, NICKNAME: item.NICKNAME, PHONE_NUMBER: item.PHONE_NUMBER, PHONE_PREFIX: item.PHONE_PREFIX, DOORKEY: item.DOORKEY, ACCOUNTNUMBER: item.ACCOUNTNUMBER, TOPAY_TOTAL: item.TOPAY_TOTAL, PAYEDTO_DT: item.PAYEDTO_DT, MESSAGE: item.MESSAGE, not_payed_add_person: item.not_payed_add_person});
		setShowEditUser(true);
	};

	const closeEditUser = () => {
		setShowEditUser(false);
	};

	const updateUserRow = () => {};

	const downloadDo = () => {
		if (usrInfo.usrId === 1) {
			setLoading(true);

			post('downloadTrans', {TYPEID: 2, DTFROM: downloadFrom, DTTO: downloadTo})
				.then((res) => {
					getData('', 0);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	const savePermanent = (index, id, val) => {
		setLoading(true);

		post('savePermanent', {ID: id, PERNAMENTMEMBER: val})
			.then((res) => {
				data[index].PERNAMENTMEMBER = res.data;
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	let list = data.map((item, index) => {
		return (
			<div className={`list`} key={index}>
				<div className="list_in" style={{paddingLeft: '0.4rem', paddingRight: '0.4rem'}}>
					<div className="fl_l txt_l">
						{item.COLUMN0}
						<b>
							{item.USRID > 0 ? (
								<>
									&nbsp;-&nbsp;{item.LASTNAME} {item.FIRSTNAME} {item.NICKNAME !== '' ? `(${item.NICKNAME})` : ''}{' '}
								</>
							) : (
								<></>
							)}
						</b>
						<div className="zero h05" />
						{item.COLUMN10}
						<div className="zero h05" />
						<span className="lnk2 cr_p" onClick={() => clipboard(item.COLUMN2 + '/' + item.COLUMN3)}>
							{item.COLUMN2}/{item.COLUMN3}
						</span>
						<div className="zero h05" />
						{item.COLUMN12}&nbsp;
						<div className="zero h05" />
						{item.COLUMN8}&nbsp;
					</div>
					<div className="fl_r">
						<div className="fl_r">
							{usrInfo.usrId === 1 ? (
								<>
									<span className="fl_r fnt_11" dangerouslySetInnerHTML={{__html: item.LAST_RES_DT}}></span>
								</>
							) : (
								<>
									<span className="fl_r fnt_12">&nbsp;</span>
								</>
							)}
							<div className="zero h05" />
						</div>
						<div className="zero h05" />

						<div className="fl_r" style={{height: '2.2rem'}}>
							{item.USRID > 0 ? (
								<>
									{usrInfo.usrId === 1 ? (
										<>
											<Link to={`/user/${item.LASTNAME} ${item.FIRSTNAME}`} key="fl_r lnk_usr_trans">
												<div className="fl_r ml_1">
													<img className="icon2 user fl_r cr_p" style={{height: '1.8rem'}} alt="" />
												</div>
											</Link>
										</>
									) : (
										''
									)}

									<Link to={`/member/${item.LASTNAME} ${item.FIRSTNAME}`} key="lnk_member_trans">
										<img className="icon1 member active fl_r cr_p ml_1" style={{height: '1.8rem'}} alt="" />
									</Link>
									<Link to={`/eshopsysadmin/${item.LASTNAME} ${item.FIRSTNAME}`} key="lnk_eshop_trans">
										<img className="icon1 eshop active fl_r cr_p ml_1" style={{height: '1.8rem'}} alt="" />
									</Link>
									<Link to={`/payment/${item.LASTNAME} ${item.FIRSTNAME}`} key="lnk_payment_trans">
										<img className="icon2 permanent2 fl_r cr_p" style={{height: '1.8rem'}} alt="" />
									</Link>
								</>
							) : (
								''
							)}
							<div className="zero h0" />
						</div>
						<div className="zero h1" />

						<div className="fl_r" style={{height: '2.4rem'}}>
							{parseInt(item.ISOK) === 0 ? (
								<div className="fl_r ml_1 cr_p" onClick={() => (usrInfo.usrId === 1 ? isOK(index, item.ID) : {})}>
									<button className={`button check fl_l`}>&nbsp;</button>
								</div>
							) : (
								<div className="fl_r ml_1">
									<button className={`button check green fl_l`} style={{cursor: 'default'}}>
										&nbsp;
									</button>
								</div>
							)}

							<span className={`fl_r ${item.COLUMN1X < 0 ? 'fnt_orange' : 'fnt_green'} fnt_16 ml_1`}>
								<b>{item.COLUMN1}</b>
							</span>

							{parseInt(usrInfo.usrId) === 1 && parseInt(item.ISOK) === 0 && typeof item.HIDDENADD === 'undefined' ? (
								<div className="fl_r cr_p" onClick={() => addPayment(item)}>
									<button className={`button check red fl_l`}>+</button>
								</div>
							) : (
								''
							)}
						</div>
						<div className="zero h1" />

						<div className="fl_r" style={{height: '2.2rem'}}>
							{usrInfo.usrId === 1 && item.USRID > 0 ? (
								<>
									{usrInfo.usrId === 1 ? <img className={`icon2 permanent ${parseInt(item.PERNAMENTMEMBER) === 1 ? 'active' : ''} fl_r cr_p ml_15`} onClick={() => savePermanent(index, item.USRID, item.PERNAMENTMEMBER)} alt="" /> : ''}

									<div className="fl_r ml_15 cr_p" onClick={() => editUser(item)}>
										<img className="icon1 edit2 fl_r cr_p" style={{height: '1.8rem'}} alt="" />
									</div>
								</>
							) : (
								''
							)}
						</div>
					</div>

					{item.COLUMN16 !== '' ? (
						<>
							<div className="zero h02" />
							<div className="fl_l tx_l fnt_orange">{item.COLUMN16}</div>
						</>
					) : (
						''
					)}
					<div className="zero h0" />
				</div>
				<div className="zero h0" />
			</div>
		);
	});

	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />
				<Search showSearch searchValue={searchValue} onChangeSearch={(e) => onChangeSearch(e)}>
					<span className="fl_r mt_05">{info}</span>
				</Search>
				<PaymentNew showNew={showNew} closeNew={closeNew} updateRow={updateRow} dataNew={dataNew} dataUser={dataUser} />
				<UserEdit showEdit={showEditUser} closeEdit={closeEditUser} updateUserRow={updateUserRow} data={dataEditUser} />

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>
					{usrInfo.usrId === 1 ? (
						<>
							<div className="fl_l mr_05" style={{width: '11rem'}}>
								<FormDate label="" maxLength="10" readOnly iconPosTop="0.9rem" value={downloadFrom} setValue={setDownloadFrom} ref={downloadFromRef} error="" />
							</div>
							<span className="fl_l" style={{marginTop: '8px'}}>
								-
							</span>
							<div className="fl_l ml_05" style={{width: '11rem'}}>
								<FormDate label="" maxLength="10" readOnly iconPosTop="0.9rem" value={downloadTo} setValue={setDownloadTo} ref={downloadToRef} error="" />
							</div>
							<button
								className="fl_l button red ml_1 mt_03"
								onClick={() => {
									downloadDo();
								}}
							>
								Download
							</button>
							<div className="zero h0" />

							<span className="fl_l fnt_12 txt_l ml_05" dangerouslySetInnerHTML={{__html: dataLog}}></span>
							<div className="zero h1" />
						</>
					) : (
						''
					)}

					{list}

					{parseInt(loadedCnt) === parseInt(usrInfo.LIST_ROWS) ? (
						<button className="button small loadNext" onClick={() => loadNext()}>
							Načíst další
						</button>
					) : (
						<span className="fnt_12 loadNext">Byly načteny všechny záznamy</span>
					)}
				</div>
			</div>
		</>
	);
}
