import React, {useState, useEffect} from 'react';
import post from '../utils/post.js';
import AppHeader from '../components/AppHeader.js';
import Search from '../components/Search.js';
import clipboard from '../utils/clipboard.js';
import convertDataString from '../utils/convertDataString.js';
import $ from 'jquery';
import UserEdit from '../components/UserEdit.js';
import UserQR from './UserQR.js';
import {Link} from 'react-router-dom';

export default function User({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [info, setInfo] = useState('');
	const [showQR, setShowQR] = useState(false);
	const [qrInfo, setQrInfo] = useState({});
	const [dataDetail, setDataDetail] = useState({});
	const [showEdit, setShowEdit] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [offset, setOffset] = useState(0);
	const [loadedCnt, setLoadedCnt] = useState(0);

	useEffect(() => {
		let search = '';

		if (typeof window.location.pathname.split('/')[2] != 'undefined') {
			search = decodeURI(window.location.pathname.split('/')[2]);
			setSearchValue(search);
		}

		getData(search, offset);

		if (search !== '') {
			window.history.pushState({}, document.title, '/user');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangeSearch = (value) => {
		setSearchValue(value);
		setOffset(0);
		getData(value, 0);
	};

	const loadNext = () => {
		setOffset((prevOffset) => {
			let newOffset = prevOffset + usrInfo.LIST_ROWS;
			getData(searchValue, prevOffset + usrInfo.LIST_ROWS);
			return newOffset;
		});
	};

	const getData = (search, offset) => {
		setLoading(true);

		post('getListUser', {search: search, offset: offset})
			.then((res) => {
				if (offset === 0) {
					setData(res.data.data);
				} else {
					setData((prevRows) => {
						const updatedRows = [...data, ...res.data.data];
						return updatedRows;
					});
				}

				setLoadedCnt(res.data.data.length);

				setInfo('Uživatelů: ' + res.data.data_cnt);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const saveAddPersonPayed = (index, index2, id, val) => {
		setLoading(true);

		post('saveAddPersonPayed', {ID: id, ADD_PERSON_PAYED: val})
			.then((res) => {
				data[index].not_payed_add_person[index2].ADD_PERSON_PAYED = res.data;
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const saveMember = (index, id, val) => {
		setLoading(true);

		post('saveMember', {ID: id, CLUBMEMBER: val})
			.then((res) => {
				data[index].CLUBMEMBER = res.data;
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const savePermanent = (index, id, val) => {
		setLoading(true);

		post('savePermanent', {ID: id, PERNAMENTMEMBER: val})
			.then((res) => {
				data[index].PERNAMENTMEMBER = res.data;
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const saveActive = (index, id, val) => {
		if (id !== 1) {
			setLoading(true);

			post('saveActive', {ID: id, ACTIVE: val})
				.then((res) => {
					data[index].ACTIVE = res.data;
					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	const saveUnlock = (index, id, locked) => {
		if (locked === 1) {
			setLoading(true);

			post('saveUnlock', {ID: id})
				.then((res) => {
					data[index].LOCKED = 0;
					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	const logAsUser = (id) => {
		setLoading(true);

		post('logAsUser', {ID: id})
			.then((res) => {
				window.location.href = '/';
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const testEmail = (id) => {
		setLoading(true);

		post('testEmail', {ID: id})
			.then((res) => {
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const editUser = (rec) => {
		setShowEdit(true);
		setDataDetail(rec);
	};

	const closeEdit = () => {
		setShowEdit(false);
	};

	const updateUserRow = (username, firstName, lastName, nickname, phone, prefix, doorKey, id, message, payedtoDT) => {
		data?.forEach((line, index) => {
			if (parseInt(line.ID) === parseInt(id)) {
				data[index].EMAIL = username;
				data[index].FIRSTNAME = firstName;
				data[index].LASTNAME = lastName;
				data[index].NICKNAME = nickname;
				data[index].PHONES = '+' + prefix + ' ' + phone[0] + phone[1] + phone[2] + ' ' + phone[3] + phone[4] + phone[5] + ' ' + phone[6] + phone[7] + phone[8];
				data[index].DOORKEY = doorKey;
				data[index].MESSAGE = message;
				data[index].PAYEDTO_DT = payedtoDT;

				if (convertDataString(payedtoDT) >= new Date().toISOString().slice(0, 10)) {
					data[index].PAYEDTO_DT_IN = 1;
				} else {
					data[index].PAYEDTO_DT_IN = 0;
				}

				setData([...data]);
			}
		});
	};

	useEffect(() => {
		if (showQR) {
			$('#QRCODE').html(qrInfo?.QR);
			$('#QRTXT').html(qrInfo?.VCARD2);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showQR]);

	const getQRData = (id) => {
		setLoading(true);

		post('getQRData', {ID: id})
			.then((res) => {
				setQrInfo(res.data);
				setShowQR(true);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const closeQR = () => {
		setShowQR(false);
	};

	const handleRefresh = () => {
		getData(searchValue, offset);
	};

	let list = [];

	data?.forEach((line, index) => {
		let usrName = '';
		if (line.NICKNAME !== '') {
			usrName = `${line.LASTNAME} ${line.FIRSTNAME} (${line.NICKNAME})`;
		} else {
			usrName = `${line.LASTNAME} ${line.FIRSTNAME}`;
		}

		let listNotPayedAddPerson = [];

		line.not_payed_add_person.forEach((line2, index2) => {
			listNotPayedAddPerson.push(
				<div className="list announce" key={`NOT_PAYED_ADD_PERSON` + index2}>
					<div className="list_in">
						<span className="fl_l">{line2.RESERVATIONDTS}</span>
						<img className={`icon1 ${parseInt(line2.ADD_PERSON_PAYED) === 0 ? 'ok0' : 'ok1'} fl_r cr_p ml_2`} onClick={() => saveAddPersonPayed(index, index2, line2.ID, line2.ADD_PERSON_PAYED)} alt="" />
						<span className="fl_r">{line2.NOT_PAYED_PRICES},-</span>
						<div className="zero h0" />
					</div>
				</div>
			);
		});

		let listReservation = [];

		line.RESERVATIONS?.forEach((line2, index2) => {
			listReservation.push(
				<div key={`reslst_` + index2}>
					<div className="fl_l">
						<div className={`fl_l txt_l ${parseInt(line2.IS_TODAYORFUTURE) === 1 ? 'fnt_green' : ''}`}>
							{line2.RESERVATIONDT} - {line2.RESERVATIONLNG_H}h{line2.ADD_PERSON > 0 ? ` +${line2.ADD_PERSON}x` : ''}
						</div>
					</div>
					<div className="zero h0" />
				</div>
			);
		});

		list.push(
			<div className={`list ${parseInt(line.ACTIVE) === 0 ? ' inactive' : ''}`} key={index}>
				<div className="list_in">
					<div className="fl_l">
						<div className="fl_l fnt_14 lnk2 cr_p" onClick={() => clipboard(usrName)}>
							<b>
								{line.LASTNAME} {line.FIRSTNAME}
							</b>{' '}
							{line.NICKNAME !== '' ? `(${line.NICKNAME})` : ''}
						</div>
						<div className="zero h07" />

						<div className="fl_l">
							{line.LASTACTIVITYDTS} <span className="fnt_orange">({line.USR_RECORDS}x)</span>
						</div>
						<div className="zero h07" />

						<a className="lnk1 fl_l cr_p" href={`mailto:${line.EMAIL}?subject=Progress Gym&body=`}>
							{line.EMAIL}
						</a>

						<div className="zero h07" />

						<a className="lnk1 fl_l cr_p" href={`tel:+${line.PHONES}`}>
							{line.PHONES}
						</a>
					</div>

					<div className="fl_r">
						<div className={`fl_r cr_p ${parseInt(line.ACTIVE) === 1 ? ' fnt_orange' : ''}`} style={{width: '4.2rem', textAlign: 'right'}} onClick={() => saveActive(index, line.ID, line.ACTIVE)}>
							<b>{line.ID}</b>
						</div>

						{line.MESSAGE != '' ? (
							<div className="icon_message small fl_r mt_02" />
						) : (
							''
						)}

						<div className="zero h05" />

						<img className={`icon2 lock ${parseInt(line.LOCKED) === 1 ? 'active' : ''} fl_r ${parseInt(line.LOCKED) === 1 ? 'cr_p' : ''}`} onClick={() => saveUnlock(index, line.ID, line.LOCKED)} alt="" />
						<img className={`icon2 key ${line.DOORKEY !== '' ? 'active' : ''} fl_r mr_2`} alt="" />
						<img className={`icon2 permanent ${parseInt(line.PERNAMENTMEMBER) === 1 ? 'active' : ''} fl_r cr_p mr_2`} onClick={() => savePermanent(index, line.ID, line.PERNAMENTMEMBER)} alt="" />
						<img className={`icon2 member ${parseInt(line.CLUBMEMBER) === 1 ? 'active' : ''} fl_r cr_p mr_2`} onClick={() => saveMember(index, line.ID, line.CLUBMEMBER)} alt="" />
						<div className="zero h2" />

						<img
							alt=""
							className="icon2 qr fl_r cr_p"
							onClick={() => {
								getQRData(line.ID);
							}}
						/>

						<img className={`icon2 edit cr_p fl_r mr_2`} onClick={() => editUser(line)} alt="" />
						<img className={`icon2 logas cr_p fl_r mr_2`} onClick={() => logAsUser(line.ID)} alt="" />

						<Link to={`/member/${line.LASTNAME} ${line.FIRSTNAME}`} key="lnk_member_trans">
							<img className={`icon2 member cr_p fl_r mr_2`} alt="" />
						</Link>
					</div>

					{listNotPayedAddPerson.length > 0 ? (
						<>
							<div className="zero h1" />
							<span className="fl_l fnt_orange">Nezaplacené doprovody:</span>
							<div className="zero h05" />
							{listNotPayedAddPerson}
						</>
					) : (
						''
					)}

					<div className="zero h0" />
					<div className="fl_l">
						<div className="zero h1" />
						<div className="fl_l fnt_orange">{listReservation}</div>
						<div className="zero h0" />
					</div>

					<div className="fl_r">
						<div className="zero h15" />
						<span className="lnk2 fl_r cr_p " onClick={() => testEmail(line.ID)}>
							TEST EMAIL
						</span>

						<div className="zero h1" />
						<span className={`fl_r ${parseInt(line.PAYEDTO_DT_IN) === 1 ? 'fnt_green' : ''} `}>
							{line.PAYEDTO_DT}
						</span>
					</div>
					<div className="zero h0" />
				</div>
			</div>
		);
	});

	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />

				<Search showSearch searchValue={searchValue} onChangeSearch={(e) => onChangeSearch(e)}>
					<div className="icon_refresh_main mr_1" onClick={() => handleRefresh()}>
						<img className="icon_refresh fl_l cr_p mt_01" alt="" />
					</div>

					<span className="fl_l mt_02">{info}</span>
				</Search>
				<UserEdit showEdit={showEdit} closeEdit={closeEdit} updateUserRow={updateUserRow} data={dataDetail} />
				<UserQR showQR={showQR} closeQR={closeQR} qrInfo={qrInfo} />

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>
					{list}

					{parseInt(loadedCnt) === parseInt(usrInfo.LIST_ROWS) ? (
						<button className="button small loadNext" onClick={() => loadNext()}>
							Načíst další
						</button>
					) : (
						<span className="fnt_12 loadNext">Byly načteny všechny záznamy</span>
					)}
				</div>
			</div>
		</>
	);
}
